import { mapActions, mapGetters } from "vuex";
import { map } from "lodash";
import product from "../../../../components/product/index.vue";

export default {
    name: "profile-favorites",
    components: {
        product,
    },
    computed: {
        ...mapGetters({
            favourites: "favorites/whichList",
        }),
        favoritesProducts() {
            return map(this.favourites, "product.data");
        },
    },
    methods: {
        ...mapActions({}),
    },
};
